import "core-js/modules/es.array.push.js";
import vueQr from "vue-qr";
import { product_host } from "@/common/utils/config.js";
export default {
  props: {},
  data() {
    return {
      logoSrc: "http://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png",
      qrText: product_host + "/shareDownloadApp/index.html",
      //页面地址',

      curWay: 1,
      unReadCount: "",
      //未读消息数量
      lang: localStorage.getItem("lang"),
      isLogin: "" //是否登录
    };
  },

  components: {
    vueQr
  },
  // watch: {
  //   $route(val, from) {
  //     // 拿到目标参数 val.query.id 去请求接口
  //     console.log(val);
  //     if (this.isLogin) {
  // 	console.log(123456);
  //       this.getUnread();
  //     }
  //   },
  // },

  mounted() {
    // this.getUnread();
    this.isLogin = this.$store.state.userinfo;
    this.curWay = this.$route.query.curWay;
    if (this.isLogin) {
      console.log(12345678);
      this.getUnread();
    }
  },
  methods: {
    // typeClick() {
    // 	this.$store.commit('changeShowFilter');
    // },
    btnClick(val) {
      this.curWay = val;
      if (val == 1) {
        this.$router.push({
          path: "/accountLogin",
          query: {
            curWay: 1
          }
        });
      } else {
        this.$router.push({
          path: "/registerAccount",
          query: {
            curWay: 2
          }
        });
      }
    },
    // 去个人中心
    toPersonClick() {
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      this.$router.push({
        // path: '/home/person/personInfor',
        path: "/person",
        query: {
          curCenterIndex: 0
        }
      });
    },
    // 获取未读消息
    getUnread() {
      this.$http.getUnread().then(res => {
        if (res.code == 1) {
          this.unReadCount = res.data.num;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 去消息
    toMessage() {
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      this.$router.push({
        path: "/myMessage",
        query: {
          curCenterIndex: 1
        }
      });
    },
    // 下拉框点击事件
    commandClick(e) {
      this.lang = e;
      this._i18n.locale = e;
      localStorage.setItem("lang", e);
    },
    // 退出登录
    exitLogin() {
      localStorage.removeItem("userinfo");
      this.$store.commit("edituserinfo", "");
      this.$router.push("/accountLogin");
    },
    // 小标题点击
    itemClick(val) {
      console.log(val);
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }

      // console.log(this.$store.state.isShowFilter,'就加快速度')
      // return false;
      if (val == 4 || val == 5) {
        if (!this.$store.state.userinfo) {
          this.$message.error(this.$t("other.noLoginPleaseToLogin"));
          setTimeout(() => {
            this.$router.push({
              path: "/accountLogin"
            });
          }, 1500);
          return;
        } else {
          this.$router.push({
            path: "/talentRecruitment"
          });
        }
      }
      switch (val * 1) {
        case 1:
          this.$router.push("/");
          break;
        case 2:
          this.$router.push({
            // path: '/home/aboutUs', //曹梦玲写的
            path: "/aboutUs" //关于我们
          });

          break;
        case 3:
          this.$router.push({
            // path: '/home/contactUs',//曹梦玲写的
            path: "/contactUs" //联系我们
          });

          break;
        case 4:
          this.$router.push({
            // path: '/home/talentRecruitment', //曹梦玲写的
            path: "/talentRecruitment" //人才招聘
          });

          break;
        case 5:
          this.$router.push({
            // path: '/home/person/applyAd?curCenterIndex=7',//曹梦玲写的
            path: "/applyAd?curCenterIndex=7" //申请广告
          });

          break;
      }
    }
  }
};