<template>
  <div class="footer">
    <div class="left">
      <img src="@/assets/new_login/small_logo.png" alt="" />
      <div class="border"></div>
    </div>
    <div class="file">
      <div class="left_box">
        <div class="title">{{ $t("dialog.technicalSupport") }}</div>
        <div class="item" v-for="(item, index) in articleList" :key="index">
          <div @click="showDialog(item)">
            {{ lang == "zh" ? item.title : item.sp_title }}
          </div>
        </div>
      </div>
      <div class="right_box">
        <div class="title">{{ $t("dialog.LegalClause") }}</div>
        <div class="item" v-for="(item, index) in articleListFL" :key="index">
          <div @click="showDialog(item)">
            {{ lang == "zh" ? item.title : item.sp_title }}
          </div>
        </div>
      </div>
    </div>
    <div class="img_center">
      <div class="left">
        <img src="@/assets/bottom_icons/yappy.png" alt="" />
        <img src="@/assets/bottom_icons/paypal.png" alt="" />
      </div>
      <div class="right">
        <img src="../assets/bottom_icons/1.png" alt="" />
        <img src="../assets/bottom_icons/2.png" alt="" />
        <img src="../assets/bottom_icons/3.png" alt="" />
        <img src="../assets/bottom_icons/4.png" alt="" />
        <img src="../assets/bottom_icons/5.png" alt="" />
      </div>
    </div>
    <!-- <div class="center">
      <div>
        备案/许可证编号：豫ICP备120***02号-1-www.benbenkeji.com Copyright ©
        2015-2018富升网 All R***ts Re**rved. 复制必究
      </div>
      <div>
        网城平台备字（2019）第***号 增值电信业务许可证
        网络食品经营备案（*）1234789562544* 食品经营许可证
      </div>
      <div>违法举报电话：12345678910 知识产权侵权投诉</div>
    </div> -->
    <div class="right">
      <div class="qrcode">
        <div>
          <vue-qr
            :text="qrText"
            :size="188"
            :logoSrc="logoSrc"
            class="qrcode_img"
          ></vue-qr>
        </div>
        <div class="download_title">
          {{ $t("scanCodeToDownload") }}
        </div>
      </div>
      <div class="download">
        <div class="icon">
          <img src="../assets/bottom_icons/ios.png" alt="" />
          <div class="text">APPStore</div>
        </div>
        <div class="icon">
          <img src="../assets/bottom_icons/and.png" alt="" />
          <div class="text">Android</div>
        </div>
      </div>
    </div>

    <!-- 文件弹窗 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="38%">
      <div slot="title">
        <div class="title_all">
          <div>{{ lang == "zh" ? name : sp_name }}</div>
        </div>
      </div>
      <div class="content" v-html="content" v-if="lang == 'zh'"></div>
      <div class="content" v-html="sp_content" v-else></div>
    </el-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { product_host } from "@/common/utils/config.js";

export default {
  data() {
    return {
      logoSrc:
        "https://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png",
      qrText: product_host + "/shareDownloadApp/index.html", //页面地址',

      dialogVisible: false, //文件弹框

      content: "",
      sp_content: "",
      name: "",
      sp_name: "",
      lang: "",
      articleList: [],
      articleListFL: [],
    };
  },
  components: {
    vueQr,
  },
  mounted() {
    this.lang = localStorage.getItem("lang");

    this.getBottomArticle(75);
    this.getBottomArticle(76);

    window.addEventListener("setItemEvent", (e) => {
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });
  },

  methods: {
    showDialog(item) {
      console.log(item);
      this.dialogVisible = true;
      this.name = item.title;
      this.sp_name = item.sp_title;
      this.content = item.body;
      this.sp_content = item.sp_body;
      console.log(this.sp_name, 1234);
    },
    // 获取底部文章列表
    getBottomArticle(id) {
      this.$http
        .bottomArticle({
          category_id: id,
          type: 2,
        })
        .then((res) => {
          if (res.code == 1) {
            if (id == 75) {
              this.articleList = res.data.data;
            } else {
              this.articleListFL = res.data.data;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style scoped="" lang="less">
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 190px;
  background-color: #fff;
  padding: 0 100px 0 100px;

  .left {
    display: flex;
    justify-content: space-between;
    img {
      width: 230px;
      height: 130px;
      object-fit: contain;
    }

    .border {
      margin-left: 50px;
      width: 1px;
      height: 144px;
      background: #eee;
    }
  }
  .file {
    display: flex;
    justify-content: space-between;

    .left_box {
      margin-right: 100px;
    }

    .title {
      margin-bottom: 10px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 800;
      white-space: nowrap;
    }
    .item {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;

      div {
        margin-bottom: 2px;
        cursor: pointer;
      }
    }
  }

  .img_center {
    display: flex;
    justify-content: space-between;

    .left {
      margin-right: 50px;
      img {
        width: 170px;
        height: 120px;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;

    div {
      margin-bottom: 16px;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;

    .qrcode {
      .qrcode_img {
        width: 112px;
        height: 122px;
      }

      .download_title {
        font-size: 14px;
        color: #333;
      }
    }

    .download {
      margin-left: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 112px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border: 1px solid #f39800;
        opacity: 1;
        border-radius: 4px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }

        .text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f39800;
        }
      }
    }
  }

  .title_all {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-left: 28px;

    .title_img {
      margin-right: 8px;

      img {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
      }
    }
  }

  .content {
    font-size: 14px;
    text-align: left;
    padding: 0 28px;
  }

  /deep/ .el-dialog__body {
    max-height: 436px;
    overflow-y: scroll;
  }

  /deep/.el-dialog__header {
    border-bottom: 1px solid #ddd !important;
  }

  /deep/ p {
    word-break: break-word !important;
  }
}
</style>