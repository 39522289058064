var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "login_head isShadow"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top_left"
  }, [_c('el-dropdown', {
    attrs: {
      "size": "small"
    },
    on: {
      "command": _vm.commandClick
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" " + _vm._s(_vm.lang == "zh" ? "中文" : "Español")), _c('i', {
    staticClass: "el-icon-caret-bottom el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "command": "zh"
    }
  }, [_vm._v("中文")]), _c('el-dropdown-item', {
    attrs: {
      "command": "sp"
    }
  }, [_vm._v("Español")])], 1)], 1)], 1), _c('div', {
    staticClass: "top_right"
  }, [_vm.$store.state.userinfo ? _c('div', {
    staticClass: "item",
    staticStyle: {
      "display": "flex"
    },
    on: {
      "click": function ($event) {
        return _vm.itemClick(4);
      }
    }
  }, [_vm._m(0), _c('div', [_vm._v(" " + _vm._s(_vm.$t("talent")) + " ")])]) : _vm._e(), !_vm.$store.state.userinfo ? _c('div', {
    class: ['way', _vm.curWay == 1 ? 'way_select' : ''],
    on: {
      "click": function ($event) {
        return _vm.btnClick(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.login")) + " ")]) : _vm._e(), !_vm.$store.state.userinfo ? _c('div', {
    class: ['way', _vm.curWay == 2 ? 'way_select' : ''],
    on: {
      "click": function ($event) {
        return _vm.btnClick(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.regiser")) + " ")]) : _vm._e(), _vm.$store.state.userinfo ? _c('div', {
    staticClass: "message",
    on: {
      "click": _vm.toMessage
    }
  }, [_vm._m(1), _c('div', [_vm._v(" " + _vm._s(_vm.$t("message"))), _vm.unReadCount != 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.unReadCount) + ")")]) : _vm._e()])]) : _vm._e(), _c('el-dropdown', {
    attrs: {
      "size": "small"
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_c('div', {
    staticClass: "download"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/new_login/download.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t("download")))])])]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('div', {
    staticClass: "qrcode"
  }, [_c('div', [_c('vue-qr', {
    attrs: {
      "text": _vm.qrText,
      "size": 188,
      "logoSrc": _vm.logoSrc
    }
  })], 1), _c('div', {
    staticClass: "download_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("scanCodeToDownload")) + " ")])])])], 1), _vm.$store.state.userinfo ? _c('el-dropdown', {
    attrs: {
      "size": "small"
    },
    on: {
      "command": _vm.exitLogin
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link",
    on: {
      "click": _vm.toPersonClick
    }
  }, [_c('div', {
    staticClass: "person"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/person.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$store.state.userinfo.user_nickname))]), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })])]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', [_vm._v(_vm._s(_vm.$t("set.exitLogin")))])], 1)], 1) : _vm._e()], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    staticStyle: {
      "width": "18px",
      "height": "18px",
      "margin-right": "7px"
    },
    attrs: {
      "src": require("@/assets/new_login/rencai.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "message_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/message.png")
    }
  })]);
}];
export { render, staticRenderFns };